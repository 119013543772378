import * as ActionTypes from './types';
//export const BASE ="http://127.0.0.1:8000/api";
//export const BASE_URL ="http://127.0.0.1:8000/api/";
//export const BASE_URL ="https://dev.casediary.com.bd/api/";
export const BASE_URL ="https://casediary.com.bd/api/";
//export const BASE_IMAGE ="http://127.0.0.1:8000/uploads/";
//export const BASE = "https://dev.casediary.com.bd/api";
export const BASE = "https://casediary.com.bd/api";
export const VERSION = 'v1.0';
//export const BASE_IMAGE = "https://dev.casediary.com.bd/uploads/";
export const BASE_IMAGE = "https://casediary.com.bd/uploads/";
export * from './auth_actions';

